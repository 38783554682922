<template>
    <div class="agile-agreement">
        <div class="main">
            <!-- <div class="title">
                为了更好的为您提供报酬发放服务，请阅读并同意以下协议：
            </div> -->
            <div class="agreement-content" v-html="info.agreementContent"></div>
        </div>
        <div class="agile-agreement-footer">
            <div class="btns">
                <div class="btn agree" @click="jumpHomePage">同意签署</div>
            </div>
        </div>
    </div>
</template>

<script>
import { listAgreements, agreementCallback } from '@/api/agreement';
export default {
    data() {
        return {
            info: {},
        };
    },
    created() {
        this.loadInfo();
    },
    methods: {
        loadInfo() {
            listAgreements().then((res) => {
                let { code, data, message } = res;
                if (code === 0) {
                    if (data && data.agreementContent) {
                        data.agreementContent = data.agreementContent.replace(/&nbsp;/g, '');
                    }
                    this.info = data;
                } else {
                    this.$toast({
                        message,
                    });
                }
            });
        },
        jumpHomePage() {
            this.$store.dispatch('app/setLoading', false);
            this.$toast.loading({
                message: '加载中...',
                duration: 10000,
            });
            agreementCallback({ laborId: this.info.laborId }).then((res) => {
                let { code, message } = res;
                if (code === 0) {
                    this.$toast.success({
                        message: '签署成功',
                        duration: 1000,
                    });
                    setTimeout(() => {
                        this.$router.replace({
                            path: 'index',
                        });
                    }, 1000);
                } else {
                    this.$toast({
                        message,
                    });
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.agile-agreement {
    width: 100%;
    background: #fff;
    height: 100%;

    .main {
        padding: 70px 20px 0px 20px;

        .title {
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #4d4d4f;
            margin-bottom: 20px;
        }

        .agreement-content {
            height: calc(100vh - 138px);
            overflow-y: auto;
            padding-bottom: 60px;
            white-space: pre-wrap;
        }
    }
}

.agile-agreement-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 86px;
    background: #ffffff;
    box-shadow: 0px 0px 13px 0px rgba(192, 195, 208, 0.45);
    padding: 18px 20px;

    .btns {
        display: flex;
        justify-content: center;
    }

    .btn {
        width: 240px;
        height: 44px;
        line-height: 44px;
        border: 1px solid #dfdfe6;
        border-radius: 22px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #575759;
        margin-left: 13px;
    }

    .btn:active {
        opacity: 0.8;
    }

    .btn.agree {
        background: linear-gradient(-90deg, #4b62bd, #5267bf);
        color: #fff;
    }
}</style>
